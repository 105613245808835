import Cookies from 'js-cookie';
import {
  CompanyQuestionsEnabledFields,
  acceptedExtentions,
  CalculatorsListData,
} from './constants';

export const isDraftFilled = (draft) =>
  !!draft.address1 &&
  !!draft.companyId &&
  !!draft.companyName &&
  !!draft.countryId &&
  !!draft.zipCode &&
  !!draft.establishedDate &&
  !!draft.id &&
  ((draft.type !== 'global' && !!draft.industrialGroupId) ||
    (draft.type === 'global' && !!draft.subIndustryId)) &&
  !!draft.legalTypeId &&
  !!draft.name &&
  !!draft.periodReportDate &&
  (!CalculatorsListData[draft.type].requiredFields.numberFields ||
    CalculatorsListData[draft.type].requiredFields.numberFields.every((f) => !!draft[f])) &&
  (!CalculatorsListData[draft.type].requiredFields.stringFields ||
    CalculatorsListData[draft.type].requiredFields.stringFields.every((f) => !!draft[f]));

// !!draft.annualProductivityEachPosition &&
// !!draft.netProfit &&
// !!draft.yearTaxes &&
// !!draft.annualTurnover &&
// !!draft.numberUniqueProducts &&
// !!draft.employeesNumber &&

export const initCompanyAnswer = (code) => {
  const answer = {};
  CompanyQuestionsEnabledFields[code].forEach((ckey) => {
    const key = ckey.split('_')[0];
    answer[key] = '';
  });
  answer.scopeId = '';
  answer.data = '';
  answer.info = '';
  answer.filled = false;
  answer.isNotAvailable = false;
  return answer;
};

export const initCompanyAnswers = () => {
  const answers = {};
  Object.keys(CompanyQuestionsEnabledFields).forEach((code) => {
    answers[code] = [];
    answers[code].push(initCompanyAnswer(code));
  });
  return answers;
};

/* eslint-disable import/prefer-default-export */
export const formatDate = (date) => {
  if (date === null || date === undefined) return null;
  if (typeof date === 'number' && date === parseInt(date, 10)) {
    return `${date}-01-01 00:00:00`;
  }
  const newDate = new Date(date);
  if (!Number.isNaN(newDate.getTime())) {
    const isoDate = newDate.toISOString().split('T')[0];
    return `${isoDate} 00:00:00`;
  }
  return null;
};

/* eslint-disable import/prefer-default-export */
export const formatDateTime = (date) => {
  if (!(date instanceof Date)) return '';

  const isoDate = date.toISOString().split('T')[0];
  const isoTime = date.toISOString().split('T')[1].split('.')[0];
  return `${isoDate} ${isoTime}`;
};

export const validateFile = (ext, size, calcName) => {
  const exts = acceptedExtentions[calcName] || acceptedExtentions.COMMON;
  return (
    ((exts.acceptedImages && exts.acceptedImages.includes(ext)) ||
      (exts.acceptedDocs && exts.acceptedDocs.includes(ext))) &&
    size < 1e8
  );
};

export const getFileType = (ext, calcName) => {
  const exts = acceptedExtentions[calcName] || acceptedExtentions.COMMON;
  if (exts.acceptedImages && exts.acceptedImages.includes(ext)) return 'image';
  if (exts.acceptedDocs && exts.acceptedDocs.includes(ext)) return 'doc';
  return '';
};

/**
 * рекурсивная функция для изменения свойств обьектов в camelCase
 * @param {*} obj - обект со свойствами в виде snake_case
 * @returns - обект со свойствами в виде camelCase
 */
export const snakeToCamel = (obj) => {
  const newObj = Object.keys(obj).reduce(
    (res, prop) => {
      const newPropName = prop.replaceAll(
        /._./g,
        (match) => `${match.split('_')[0]}${match.split('_')[1].toUpperCase()}`
      );
      const newPropValue =
        obj[prop] && typeof obj[prop] === 'object' ? snakeToCamel(obj[prop]) : obj[prop];
      return Array.isArray(obj) ? [...res, newPropValue] : { ...res, [newPropName]: newPropValue };
    },
    Array.isArray(obj) ? [] : {}
  );
  return newObj;
};

export const openAppLoader = () => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = 1;
    loader.style.visibility = 'visible';
  }
};

export const closeAppLoader = () => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = 0;
    loader.style.visibility = 'hidden';
  }
};

export const logoutUserHandler = (logoutApiMethod, disconnectSocketMethod, withRedirect = true) => {
  openAppLoader();

  logoutApiMethod()
    .then((response) => {
      if (response.data.logout) {
        const domainValue = process.env.REACT_APP_DOMAIN;
        Cookies.remove('access_token', { domain: domainValue });
        Cookies.remove('userId', { domain: domainValue });
        Cookies.remove('companyId', { domain: domainValue });
        Cookies.remove('calculatorId', { domain: domainValue });
        Cookies.remove('last_active', {
          domain: domainValue,
        });
        localStorage.removeItem('currentUserData');
        localStorage.removeItem('currentCompanyData');
        disconnectSocketMethod();

        if (withRedirect) {
          window.location.href = '/logout';
        } else {
          window.location.reload();
        }
      } else {
        closeAppLoader();
      }
    })
    .catch(() => {
      closeAppLoader();
      const domainValue = process.env.REACT_APP_DOMAIN;
      Cookies.remove('access_token', { domain: domainValue });
      Cookies.remove('userId', { domain: domainValue });
      Cookies.remove('calculatorId', { domain: domainValue });
      Cookies.remove('last_active', {
        domain: domainValue,
      });
      disconnectSocketMethod();

      if (withRedirect) {
        window.location.href = '/logout';
      } else {
        window.location.reload();
      }
    });
};
