const ttMainHost = process.env.REACT_APP_SSO_HOST;
const ttMarketHost = process.env.REACT_APP_MARKET;

export const NumberFilter = /^((\d+[,|.])?(\d+)?)$/;

export const CALC_TYPES = {
  CARBON: 'carbon',
  LOCAL: 'local',
  GLOBAL: 'global',
  PROJECT: 'project',
};

export const GetTypesAsArray = () => Object.keys(CALC_TYPES).map((key) => CALC_TYPES[key]);

export const isESG = (type) =>
  type === CALC_TYPES.LOCAL || type === CALC_TYPES.GLOBAL || type === CALC_TYPES.CARBON;

export const ColorScheme = {
  scope1: '#12491D',
  scope2: '#2DA131',
  'scope3-1': '#01A0C6',
  'scope3-2': '#2E58A6',
  customCO2: '#12491D',
  Carbon: '#12491D',
  Environmental: '#2DA131',
  Social: '#01A0C6',
  Governance: '#2E58A6',
  Economic: '#2E58A6',
  globalEnvironmental: '#2DA131',
  globalSocial: '#01A0C6',
  globalEconomic: '#2E58A6',
};

export const CalculatorsListData = Object.freeze({
  [CALC_TYPES.LOCAL]: {
    order: 2,
    label: 'ESG Excellence',
    children: ['scope1', 'scope2', 'scope3', 'local'],
    info: `ESG Excellence is a full-featured service that allows you to get a complete picture of the social and environmental impact of your business.</p>
  <p>The TT ESG Calculator takes into account all possible indicators of a company's ESG. The service includes a set of calculators for estimating ESG metrics, including the Carbon Footprint Calculator.</p>`,
    requiredFields: {
      numberFields: [
        'numberUniqueProducts',
        'employeesNumber',
        'annualProductivityEachPosition',
        'annualTurnover',
        'netProfit',
        'yearTaxes',
      ],
      reportYear: true,
    },
    isSingleInYear: true,
  },
  [CALC_TYPES.CARBON]: {
    order: 1,
    label: 'Carbon footprint calculator',
    children: ['scope1', 'scope2', 'scope3'],
    parent: 'local',
    info: `<p>This tool allows you to calculate your organization’s carbon footprint. The calculator is based on the widely accepted GHG Carbon Footprint Protocol and consists of a Company calculator.</p>
    <p>Simply fill in the required fields. All the information will be converted into a CO2 equivalent value. You can check your results in detail and publish it if you wish.</p>`,
    requiredFields: {
      numberFields: ['numberUniqueProducts', 'employeesNumber', 'annualProductivityEachPosition'],
      reportYear: true,
    },
    isSingleInYear: true,
  },
  [CALC_TYPES.GLOBAL]: {
    order: 4,
    label: 'ESG Benchmark',
    children: ['global'],
    info: `<p>Info</p>`,
    requiredFields: {
      numberFields: [
        'numberUniqueProducts',
        'employeesNumber',
        'annualProductivityEachPosition',
        'annualTurnover',
        'netProfit',
        'yearTaxes',
      ],
      reportYear: true,
    },
    isSingleInYear: true,
  },
  [CALC_TYPES.PROJECT]: {
    order: 5,
    label: 'Project Calculator',
    children: ['scope1', 'scope2', 'scope3'],
    info: `<p>The Project Calculator allows you to calculate the carbon footprint of your projects's entire business process. According to the GHG protocol, the carbon footprint estimate is divided into three scopes. By successively filling in the data on the elements from the calculator your company uses, you can estimate your total carbon footprint and share your result.</p>`,
    requiredFields: {
      numberFields: ['numberUniqueProducts'],
      stringFields: ['name'],
    },
  },
  isSingleInYear: false,
});

export const ChartSectionsMappings = Object.freeze({
  Product: 'Product Calculator',
  Packaging: 'Packaging Calculator',
  'Scope 1': 'Company Calculator',
  'Scope 2': 'Company Calculator',
  'Scope 3': 'Company Calculator',
});

export const CalculatorsUrls = Object.freeze({
  local: '/calculators/local/1',
  scope1: '/calculators/carbon/company/scope1/1',
  scope2: '/calculators/carbon/company/scope2/1',
  scope3: '/calculators/carbon/company/scope3/1',
  global: '/calculators/global/1/1/1',
});

export const SelfAssessmentInfoText = Object.freeze({
  local: `
    <p>
      Environmental responsibility, fighting climate change, promoting human well-being, and sustainable development and management practices have become core business principles. It is essential that responsible companies measure and demonstrate their adherence to these values.
    </p>
    <p>
      The TT ESG Calculator is a full-featured service that turns abstract concepts and values into concrete figures and indicators that show the specifics of your corporate sustainability practices. The calculator allows you to enter data about your company and get a complete picture of your social and environmental impact.
    </p>
    <p>
      Compare your company&apos;s sustainability picture to general statistics and get basic advice on how to make your enterprise more environmentally sustainable. Your ESG results can be sent to the blockchain, where they can be readily accessed by stakeholders, investors, experts, and regulators.
    </p>
  `,
  carbon: `
    <p>
      Any company activity results in a carbon footprint. Each company has a different footprint.
    </p>
    <p>
      The Carbon calculator is a professional assessment tool that includes product, packaging, and company carbon footprint calculations.
    </p>
    <p>
      It is based on the UK government calculation method. Your data will be aligned to a CO2 emission equivalent to simplify its offsetting.
    </p>
    <p>
      The Carbon calculator is very flexible and you can customize it according to your company&apos;s requirements regardless of its size, location, or industrial sector.
    </p>
  `,
  global: `
    <h4>Discover true sustainability</h4>
    <p>
      Business today is about much more than finances. It's also about sustainability and management, promoting human well-being and maintaining ecological balance. Calculate your company's sustainability to show investors and customers your level of social responsibility.
    </p>
    <h4>Use advanced ESG calculation methods</h4>
    <p>
      The TransparenTerra ESG Benchmark allows you to calculate companies' environmental, social, and governance data from different industry sectors in detail.
    </p>
    <h4>Be the best in your industry</h4>
    <p>
      The TransparenTerra ESG Benchmark shows all the specifics of your company's ESG metrics. Find out every dark spot and improve your sustainable performance. Get the best scores and store them to the blockchain to improve reputation transparency and strengthen your company's partnerships, investor relations, and customer communications.
    </p>
  `,
});

export const SelfAssessmentDisclaimerText = Object.freeze({
  base: `<p>The calculation of the carbon footprint is based on the different
    parameters (activities) described below. Each activity has its own CO2
    equivalent emission amount. This amount is displayed in the management
    console. The parameters for each activity are based on the UK Government
    GHG Conversion Factors for Company Reporting data and formulas. There is
    a fee to use the calculator; the amount of the fee depends on the
    calculator you choose. In order to obtain complete and comprehensive
    self-assessment data, you must answer all questions asked. To become
    visible to interested investors, you need to submit the data to the
    blockchain. The data will be sent to FCE Group's blockchain via the
    TransparenTerra platform.</p>
    <p>We will not send raw data to the blockchain, only data hashes. All your
    data will be stored on the profile page, hashed there and sent to the
    blockchain in encrypted form. If someone wants to compare or verify the
    data on the blockchain, they will compare the hashes, not the data
    itself. The hash is generated on the profile page. The responsibility
    for the quality of the data lies solely with those who enter the data.
    The data in the blockchain is immutable and will be stored forever. To
    respect your rights under GDPR, we will send hashes of your ESG score
    results there. In your profile, experts, investors and any interested
    parties who have access to this data can compare the validity of your
    data with the hashes.</p>
    <p>The results sent to the blockchain contribute to the full transparency
    of your company and become attractive for investment. Once the fee is
    paid, it will no longer be possible to change the data.</p>
  `,
});

export const Solutions = [
  {
    name: 'Company Calculator',
    children: [{ name: 'Scope 1' }, { name: 'Scope 2' }, { name: 'Scope 3' }],
  },
  {
    name: 'Carbon Footprint Calculator',
    children: [
      {
        name: 'Company Calculator',
        href: '/calculators/carbon/company/scope1',
      },
    ],
  },
  {
    name: 'Environmental',
    children: [
      { name: "Company's Environmental Policy" },
      { name: "Company's Innovation Policy" },
      { name: 'Resource Efficiency' },
    ],
  },
  {
    name: 'Social',
    children: [
      { name: 'CSR Reporting' },
      { name: 'Community Social impact' },
      { name: 'Human Rights' },
      { name: 'Product Quality' },
      { name: 'Product Stewardship' },
      { name: 'Occupational Health and Safety' },
      { name: 'Human Capital Development' },
      { name: 'Labor Practice' },
    ],
  },
  {
    name: 'Governance',
    children: [
      { name: 'Management Efficiency' },
      { name: 'Management Meetings' },
      { name: 'Management Stability' },
      { name: 'Management Practices' },
      { name: 'Shareholders Limitations' },
      { name: 'Stakeholder Engagement' },
    ],
  },
  {
    name: 'ESG Benchmark',
    children: [
      { name: 'Environmental Dimension', href: '/calculators/global/1/1/1' },
      { name: 'Economic Dimension', href: '/calculators/global/2/1/1' },
      { name: 'Social Dimension', href: '/calculators/global/3/1/1' },
    ],
  },
  {
    name: 'Environmental Dimension',
    children: [
      { name: 'Environmental Reporting' },
      { name: 'Environmental Policy & Management System' },
      { name: 'Operational Eco-Efficiency' },
      { name: 'Climate Strategy' },
    ],
  },
  {
    name: 'Economic Dimension',
    children: [
      { name: 'Corporate Governance' },
      { name: 'Materiality' },
      { name: 'Risk & Crisis Management' },
      { name: 'Business Ethics' },
      { name: 'Policy Influence' },
      { name: 'Supply Chain Management' },
      { name: 'Tax Strategy' },
      { name: 'Information Security/Cybersecurity & System Availability' },
    ],
  },
  {
    name: 'Social Dimension',
    children: [
      { name: 'Social Reporting' },
      { name: 'Labor Practice Indicators' },
      { name: 'Human Rights' },
      { name: 'Human Capital Development' },
      { name: 'Talent Attraction & Retention' },
      { name: 'Corporate Citizenship & Philanthropy' },
      { name: 'Customer Relationship Management' },
      { name: 'Occupational Health & Safety' },
    ],
  },
];

export const Menu = [
  {
    name: 'About',
    url: '/about',
    order: 5,
  },
];

export const AddMenu = [
  {
    name: 'Offset Credits',
    url: '/offset-credits',
    order: 6,
    notForIndiv: true,
  },
];

export const UserMenu = [
  {
    name: 'Profile',
    url: '/profile',
    order: 1,
  },
  {
    name: 'New assessment',
    url: '/newassessment',
    order: 2,
    notForIndiv: true,
  },
  {
    name: 'Completed assessments',
    url: '/completed',
    order: 3,
    notForIndiv: true,
  },
  {
    name: 'Drafts',
    url: '/drafts',
    order: 4,
    notForIndiv: true,
  },
  { name: 'Help', url: '', disabled: true },
  {
    name: 'Continue editing',
    url: '/editCalculator',
    order: 7,
  },
  { name: 'hr', order: 8 },
  { name: 'Log out', order: 9, url: '/logout' },
  // { name: "Finish", url: "/finish" },
];

export const ExternalUrl = [
  {
    url: '/profile',
    externalUrl: `${ttMainHost}/dashboard/profile/`,
  },
  {
    url: '/newassessment',
    externalUrl: `${ttMainHost}/dashboard/score_and_reporting/menu/calculators/newassessment`,
  },
  {
    url: '/completed',
    externalUrl: `${ttMainHost}/dashboard/score_and_reporting/menu/calculators/completed`,
  },
  {
    url: '/drafts',
    externalUrl: `${ttMainHost}/dashboard/score_and_reporting/menu/calculators/drafts`,
  },
  {
    url: '/buy_credits',
    externalUrl: `${ttMainHost}/dashboard/buy_credits/`,
  },
  {
    url: '/login',
    externalUrl: `${ttMainHost}/sign_in/`,
  },
  {
    url: '/carbon_credits',
    externalUrl: `${ttMarketHost}/dashboard/marketplace/list/product?activeTab=carbon`,
  },
  {
    url: '/dashboard',
    externalUrl: `${ttMainHost}/dashboard`,
  },
  {
    url: '/',
    externalUrl: `${ttMainHost}/`,
  },
];

export const CalculatorDescriptions = Object.freeze({
  'Carbon Footprint Calculator': `<p>This tool allows you to calculate your organization’s carbon footprint. The calculator is based on the widely accepted GHG Carbon Footprint Protocol and consists of two parts: the Company calculator and the Product calculator.</p>
  <p>Simply fill in the required fields. All the information will be converted into a CO2 equivalent value. You can check your results in detail and publish it if you wish.</p>`,
  'Product Calculator': `The Product calculator is used to determine your company’s CO2 footprint. It consists of two parts: the Product calculator and the Product Packaging calculator. You can add any number of products and calculate the carbon footprint of each product separately. You can also track and prove the evolution of your product's footprint by recalculating your results from time to time and storing them in the blockchain.`,
  Product: `Create an emission source by adding the name of your product. Next, “build” your product (minus the packaging) using the proposed components and enter the required amounts. If necessary, add a new source for the next product.`,
  Packaging: `Create a source for your product by adding the name for your product packaging. Then build a package with the suggested ingredients and add as many as you need. If necessary, add a new source for more product packaging.`,
  'Company Calculator': `The Company Calculator allows you to calculate the carbon footprint of your company's entire business process. According to the GHG protocol, the carbon footprint estimate is divided into three scopes. By successively filling in the data on the elements from the calculator your company uses, you can estimate your total carbon footprint and share your result.`,
  'Scope 1': `Category 1 emissions (direct emissions) are emissions from activities your company owns or manages. Examples of Category 1 emissions include emissions from combustion in your owned or controlled boilers, furnaces, and vehicles, and emissions from chemical production in facilities you own or control.`,
  'Scope 2': `Category 2 (indirect energy) emissions are emissions to the atmosphere associated with the consumption of purchased electricity, heat, steam, and refrigeration. These indirect emissions result from your company’s energy use but come from sources you do not own or control.`,
  'Scope 3': `<p>Category 3 emissions (other indirect emissions) are the result of your actions associated with sources you do not own or control and are not classified as Category 2 emissions. Examples of Category 3 emissions include business travel using facilities you do not own or control, waste disposal, and your company’s purchase of materials or fuel.</p>
  <p>Well-to-tank (WTT) fuel conversion factors should be used to account for upstream Scope 3 emissions associated with the extraction, refining, and transportation of raw fuel sources to an organization’s site (or facility) prior to combustion.</p>`,
  Environmental: `Environmental criteria evaluates company's energy consumption, waste, pollution, protection of natural resources - water, air, soil, forests, etc. - treatment of animals, and actions related to climate change. The criteria can also help evaluate environmental risks the company is exposed to and how the company manages them.`,
  "Company's Environmental Policy": `An environmental policy describes how a company manages and controls the environmental impacts of its operations and supply chain. This category includes energy and water consumption, pollution prevention, waste and recycling, and chemical use.`,
  "Company's Innovation Policy": `Innovation policy is the link between research and development policy and industrial policy. It aims to provide a framework for bringing ideas to market. Innovation seeks to create initial value through new solutions for adapting to evolving industries or solving complex environmental problems. `,
  'Resource Efficiency': `Resource efficiency means using the planet's limited resources sustainably and minimizing human impact on the environment. Greater efficiency allows a company to create more with fewer resources and deliver more value, save funds and make the business more sustainable.`,
  Social: `Social factors refer to the company's strengths and weaknesses in terms of social trends, labor, and policy. This section considers management's relationships with employeesNumber, suppliers, and local communities, as well as the company's compliance with working conditions related to employee health and safety.`,
  'CSR Reporting': `The CSR (Corporate Social Responsibility) report is an internal and external document that companies use to communicate their CSR efforts and impact on society. Creating an informative report that describes your company's commitment to the community and the world is important to maintaining the company's good reputation.`,
  'Community Social impact': `Social impact on the community refers to the impact the company's activities have on people and communities. This criterion assesses the contribution that organizations make and can make to local communities.`,
  'Human Rights': `This criterion assesses whether the company's activities comply with global standards of expected behavior. Human rights are a globally agreed-upon standard of achievement for all people that encompass a broad range of interdependent civil, political, economic, social, cultural, and environmental rights.`,
  'Product Quality': `For product manufacturers, product safety risk management is of paramount importance. This criterion evaluates the quality and safety of products, including their raw materials, production methods, and compliance with delivery, sales, and consumption criteria. When a company's management effectively ensures safety at every stage, it reinforces a culture of sustainability.`,
  'Product Stewardship': `Product stewardship is an environmental management strategy. It means that anyone who designs, manufactures, sells, or uses a product takes responsibility for minimizing the product's environmental impact at all stages of the product lifecycle, including end-of-life management.`,
  'Occupational Health and Safety': `Occupational health and safety (also referred to as occupational safety, industrial hygiene, or occupational health and safety) is an interdisciplinary field concerned with people's safety, health, and well-being in the workplace. It is a crucial criterion for the modern company that values its employeesNumber and its reputation.`,
  'Human Capital Development': `This criterion evaluates the ESG principles in a company's human capital management strategies. It describes the ethics and effectiveness of working with human capital and the integration of ESG indicators into expected outcomes and plans. The additional emphasis on organizational and financial sustainability provides an opportunity to outperform the market.`,
  'Labor Practice': `Labor practices relate to hiring and promotion, compensation, disciplinary actions, the grievance system, transfers and reassignments, layoffs, human resource development, occupational safety and health, and working conditions (hours and compensation). The protection of employee rights is very important for both companies and society.`,
  Governance: `Governance in the context of ESG refers to how well management and the board of directors attend to the interests of the company's various stakeholders, including employeesNumber, suppliers, shareholders, and customers. Consideration is given to transparency of reporting, the diversity and inclusiveness of the leadership team, the balance between employee and executive compensation, and the relationship between additional executive compensation and increased long-term shareholder value.`,
  'Management Efficiency': `Management efficiency is the result a management team achieves in relation to the capital the team manages and the costs it incurs. Management efficiency determines a company's resilience to risks.`,
  'Management Meetings': `Management meetings bring together a company's leaders to solve all sorts of problems with the goal of improving the team's performance. These meetings also promote communication and understanding among all managers. The atmosphere and conduct of meetings can influence the efficiency of the entire company.`,
  'Management Stability': `Stability is the continuation of a company's current operations without major changes in direction. A company is said to have a stability strategy if it is satisfied with the same customer groups and maintaining the same market share, is satisfied with incremental improvements in functional performance, and management wants to avoid risks that might be associated with expansion or growth.`,
  'Management Practices': `<p>Management practices generally refer to the work methods and innovations that managers use to improve the effectiveness of the company's work systems.</p>
  <p>This criterion assesses the combination of tools used to support the implementation of concepts, and the processes developed by organizations to guide entrepreneurial action and direction and to influence human behavior.</p>`,
  'Shareholders Limitations': `Shareholder limitations mean control over the value and number of total outstanding shares of any class or series of general partner units. This is an important issue for listed companies that want to build trust with their investors and private shareholders.`,
  'Stakeholder Engagement': `A stakeholder engagement strategy identifies the needs of key groups, and the sponsor plays an important role in ensuring that these corporate needs are met.`,
  'ESG Benchmark': 'ESG Benchmark info',
  globalSocial: 'globalSocial info',
  globalEconomic: 'globalEconomic info',
  globalEnvironmental: 'globalEnvironmental info',
});

// ["activity", "type", "unit", "size", "additional_s", "additional_u"],
export const CompanyQuestionsEnabledFields = {
  // scope 1
  scope_1_question_1: ['activity', 'type', 'unit'], // fuels
  scope_1_question_2: ['activity', 'type', 'unit'], // bioenergy
  scope_1_question_3: ['activity', 'type'], // refrigerant_and_other
  scope_1_question_4: ['activity', 'type', 'unit'], // passenger_vehicles - removed "additional_u"
  scope_1_question_5: ['activity', 'type', 'unit'], // delivery_vehicles - removed "additional_u"
  // scope 2
  scope_2_question_1: ['activity', 'type'], // electricity
  scope_2_question_2: ['type', 'unit'], // heat_and_steam
  // scope 3 'tu'
  scope_3_question_1: ['activity', 'type', 'unit'], // wtt_fuels
  scope_3_question_2: ['activity', 'type', 'unit'], // wtt_bioenergy
  scope_3_question_3: ['activity', 'type'], // wtt_uk_and_overseas_elec
  scope_3_question_4: ['activity', 'type'], // wtt_heat_and_steam
  scope_3_question_5: ['unit'], // water_supply
  scope_3_question_6: ['unit'], // water_treatment
  scope_3_question_7: ['activity', 'type'], // material_use - removed "additional_u"
  scope_3_question_8: ['activity', 'type'], // waste_disposal- removed "additional_u"
  scope_3_question_9: ['activity', 'type', 'unit', 'size', 'additional_s'], // business_travel_air
  scope_3_question_10: ['activity', 'type', 'unit', 'size', 'additional_s'], // wtt_business_travel_air
  scope_3_question_11: ['activity', 'type', 'unit'], // business_travel_sea
  scope_3_question_12: ['activity', 'type', 'unit'], // wtt_business_travel_sea
  scope_3_question_13: ['activity', 'type', 'unit'], // business_travel_land- removed "additional_u"
  scope_3_question_14: ['activity', 'type', 'unit'], // wtt_pass_vehs_and_travel_land- removed "additional_u"
  scope_3_question_15: ['activity', 'type', 'unit'], // freighting_goods- removed "additional_u"
  scope_3_question_16: ['activity', 'type', 'unit'], // wtt_delivery_vehs_and_freight- removed "additional_u"
  scope_3_question_17: ['type'], // hotel_stay
  scope_3_question_18: ['activity', 'type'], // managed_assets_electricity
  scope_3_question_19: ['activity', 'type', 'unit'], // managed_assets_vehicles - removed "additional_u"
};

export const CalculatorPropertiesMappings = {
  material: 'activities',
  activity: 'activities',
  type: 'types',
  unit: 'units',
  class: 'classes',
};

export const DefaultCalculatorPrices = {
  currency: 'USD',
  attemptsMax: 0,
  attemptsLeft: 0,
  tariffCode: 'free',
  tariffName: 'Free',
  stepPrice: 1,
};

export const DefaultCalculatorAnswers = Object.freeze({
  TT_Env_q1: null,
  TT_Env_q2: null,
  TT_Env_q3: null,
  TT_Env_q4: null,
  TT_Inn_q1: null,
  TT_Inn_q2: null,
  TT_Inn_q3: null,
  TT_Inn_q4: null,
  TT_Inn_q5: null,
  TT_Inn_q6: null,
  TT_Inn_q7: null,
  TT_Res_q1: null,
  TT_Res_q2: null,
  TT_Res_q3: null,
  TT_Res_q4: null,
  TT_Scl_Cmm_q1: null,
  TT_Scl_Cmm_q2: null,
  TT_Scl_Cmm_q3: null,
  TT_Scl_Cmm_q4: null,
  TT_Scl_Cmm_q5: null,
  TT_Scl_Cmm_q6: null,
  TT_Scl_Cmm_q7: null,
  TT_Scl_Cmm_q8: null,
  TT_Scl_Cmm_q9: null,
  TT_Scl_Cmm_q10: null,
  TT_Hmn_Rght_q1: null,
  TT_Hmn_Rght_q2: null,
  TT_Hmn_Rght_q3: null,
  TT_Hmn_Rght_q4: null,
  TT_Hmn_Rght_q5: null,
  TT_Hmn_Rght_q6: null,
  TT_Hmn_Rght_q7: null,
  TT_Hmn_Rght_q8: null,
  TT_Prd_Qlt_q1: null,
  TT_Prd_Qlt_q2: null,
  TT_Prd_Qlt_q3: null,
  TT_Prd_Qlt_q4: null,
  TT_Prd_Qlt_q5: null,
  TT_Prd_Qlt_q6: null,
  TT_Prd_Qlt_q7: null,
  TT_Prd_Qlt_q8: null,
  TT_Prd_Qlt_q9: null,
  TT_Prd_Qlt_q10: null,
  TT_Prd_Qlt_q11: null,
  TT_Prd_Qlt_q12: null,
  TT_Prd_Qlt_q13: null,
  TT_Prd_Stw_q1: null,
  TT_Prd_Stw_q2: null,
  TT_Prd_Stw_q3: null,
  TT_Prd_Stw_q4: null,
  TT_Prd_Stw_q5: null,
  TT_Prd_Stw_q6: null,
  TT_Prd_Stw_q7: null,
  TT_Occ_Hlth_q1: null,
  TT_Occ_Hlth_q2: null,
  TT_Occ_Hlth_q3: null,
  TT_Occ_Hlth_q4: null,
  TT_Occ_Hlth_q5: null,
  TT_Occ_Hlth_q6: null,
  TT_Occ_Hlth_q7: null,
  TT_Occ_Hlth_q8: null,
  TT_Occ_Hlth_q9: null,
  TT_Occ_Hlth_q10: null,
  TT_Occ_Hlth_q11: null,
  TT_Occ_Hlth_q12: null,
  TT_Hmn_Cptl_q1: null,
  TT_Hmn_Cptl_q2: null,
  TT_Hmn_Cptl_q3: null,
  TT_Hmn_Cptl_q4: null,
  TT_Hmn_Cptl_q5: null,
  TT_Hmn_Cptl_q6: null,
  TT_Hmn_Cptl_q7: null,
  TT_Hmn_Cptl_q8: null,
  TT_Hmn_Cptl_q9: null,
  TT_Hmn_Cptl_q10: null,
  TT_Hmn_Cptl_q11: null,
  TT_Lbr_Prct_q1: null,
  TT_Lbr_Prct_q2: null,
  TT_Lbr_Prct_q3: null,
  TT_Lbr_Prct_q4: null,
  TT_Lbr_Prct_q5: null,
  TT_Lbr_Prct_q6: null,
  TT_Lbr_Prct_q7: null,
  TT_Lbr_Prct_q8: null,
  TT_Lbr_Prct_q9: null,
  TT_Lbr_Prct_q10: null,
  TT_Mang_Eff_q1: null,
  TT_Mang_Eff_q2: null,
  TT_Mang_Eff_q3: null,
  TT_Mang_Eff_q4: null,
  TT_Mang_Eff_q5: null,
  TT_Mang_Eff_q6: null,
  TT_Mang_Eff_q7: null,
  TT_Mang_Eff_q8: null,
  TT_Mang_Meet_q1: null,
  TT_Mang_Meet_q2: null,
  TT_Mang_Meet_q3: null,
  TT_Mang_Stab_q1: null,
  TT_Mang_Stab_q2: null,
  TT_Mang_Stab_q3: null,
  TT_Mang_Stab_q4: null,
  TT_Mang_Prct_q1: null,
  TT_Mang_Prct_q2: null,
  TT_Mang_Prct_q3: null,
  TT_Mang_Prct_q4: null,
  TT_Mang_Prct_q5: null,
  TT_Mang_Prct_q6: null,
  TT_Shrhld_Lmt_q1: null,
  TT_Shrhld_Lmt_q2: null,
  TT_Shrhld_Lmt_q3: null,
  TT_Shrhld_Lmt_q4: null,
  TT_Shrhld_Lmt_q5: null,
  TT_Shrhld_Lmt_q6: null,
  TT_Shrhld_Lmt_q7: null,
  TT_Shrhld_Lmt_q8: null,
  TT_Shrhld_Lmt_q9: null,
  TT_Shrhld_Lmt_q10: null,
  TT_Shrhld_Lmt_q11: null,
  TT_Shrhld_Lmt_q12: null,
  TT_Shrhld_Eng_q1: null,
  TT_Shrhld_Eng_q2: null,
  TT_Shrhld_Eng_q3: null,
  TT_Shrhld_Eng_q4: null,
  TT_Shrhld_Eng_q5: null,
  TT_Shrhld_Eng_q6: null,
  TT_Shrhld_Eng_q7: null,
  TT_Shrhld_Eng_q8: null,
  TT_Shrhld_Eng_q9: null,
  TT_Shrhld_Eng_q10: null,
  TT_Shrhld_Eng_q11: null,
  TT_CSR_Rprt_q1: null,
  TT_CSR_Rprt_q2: null,
  TT_CSR_Rprt_q3: null,
  TT_CSR_Rprt_q4: null,
  TT_CSR_Rprt_q5: null,
  TT_CSR_Rprt_q6: null,
  TT_CSR_Rprt_q7: null,
  TT_CSR_Rprt_q8: null,
  TT_CSR_Rprt_q9: null,
  TT_CSR_Rprt_q10: null,
  TT_CSR_Rprt_q11: null,
  TT_CSR_Rprt_q12: null,
  TT_CSR_Rprt_q13: null,
  TT_CSR_Rprt_q14: null,
  TT_CSR_Rprt_q15: null,
  TT_CSR_Rprt_q16: null,
  TT_CSR_Rprt_q17: null,
});

export const acceptedExtentions = {
  COMMON: {
    acceptedImages: ['jpg', 'jpeg', 'png', 'tif', 'tiff'],
    acceptedDocs: ['pdf', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx', 'csv'],
  },
};
