import axios from 'axios';
import Cookies from 'js-cookie';
import { API_BASE_API_SSO_URL } from './urls';
import { snakeToCamel } from '../../utils';

const axiosClient = axios.create({
  baseURL: API_BASE_API_SSO_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

/* eslint-disable no-param-reassign */
axiosClient.interceptors.response.use(
  (response) => {
    if (response.data) response.data = { ...snakeToCamel(response.data) };
    if (response.data && typeof response.config?.shouldTransformResponse === 'function')
      response.data = { ...response.config.shouldTransformResponse(response.data) };
    return response;
  },
  (error) => {
    // Проверяем код ошибки
    window.localStorage.setItem('error', error);
    if (error.response && error.response.status === 401) {
      // Если 401, редиректим на страницу логина
      Cookies.remove('access_token'); // очищаем токен если нужно
      Cookies.remove('userId');

      // Вызов редиректа на страницу логина (замените "/login" на нужный вам путь)
      window.location.href = '/login'; // Можно заменить на useHistory().push('/login') если используете React Router
    }
    return Promise.reject(error);
  }
);
/* eslint-enable no-param-reassign */

const axiosSSOConfigPrivate = (headers, options) => {
  const accessToken = Cookies.get('access_token');
  const userId = Cookies.get('userId');

  return {
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      Userid: userId,
      ...headers,
    },
    ...options,
  };
};

export { axiosClient, axiosSSOConfigPrivate };
