/* eslint-disable */
import clsx from 'clsx';
import PropTypes, { string } from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Grid, Button, Tooltip } from '@mui/material';
import { CalculatorsContext, DraftContext, ErrorContext } from '../../../context';

import Form from '../../../components/form/Form';
import Pagination from '../../../components/pagination/Pagination';

import styles from './index.module.scss';
import CalculationCost from '../../../components/calculationCost/CalculationCost';
import PreliminaryResults from '../Carbon/components/PreliminaryResults';
import { trackOrSetValue } from '@testing-library/user-event/dist/cjs/document/trackValue.js';

const CustomCo2 = ({}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { idx } = useParams();

  const [scopeIndex, setScopeIndex] = useState(null);
  const [preliminaryModalOpened, setPreliminaryModalOpened] = useState(false);

  const { questionsMap } = useContext(CalculatorsContext);
  const { draftId, draft } = useContext(DraftContext);

  const setAllQuestionModalOpened = () => {};

  useEffect(() => {
    setScopeIndex(idx - 1);
  }, []);

  const onBackClick = () => {
    const index = questionsMap?.carbon?.scopeMap[scopeIndex]?.end;
    navigate(`/calculators/carbon/${index}`);
  };

  const onNextClick = () => {
    setPreliminaryModalOpened(true);
  };

  const goForward = () => {
    const index = questionsMap?.carbon?.scopeMap[scopeIndex]?.end;
    if (index == questionsMap?.carbon?.end) {
      navigate(`/buy_credits/${draftId}`);
    } else navigate(`/calculators/carbon/${index + 2}`);
  };

  const closePreliminaryModal = () => {
    setPreliminaryModalOpened(false);
    goForward();
  };

  return (
    <>
      {preliminaryModalOpened && (
        <PreliminaryResults
          opened={preliminaryModalOpened}
          closeModalFunc={closePreliminaryModal}
          draftId={draftId}
          scopeIndex={scopeIndex}
        />
      )}
      <Grid
        item
        container
        wrap="nowrap"
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        className={styles.formContainer}
      >
        <div>
          <Form headerContent={<></>}>Custom CO2 items</Form>
          <CalculationCost />
        </div>
        <div className={styles.navigation}>
          <Button variant="outlined" onClick={onBackClick}>
            BACK
          </Button>
          <Button variant="outlined" onClick={() => setAllQuestionModalOpened(true)}>
            view all questions
          </Button>
          {questionsMap?.carbon && scopeIndex != null && (
            <Pagination
              activePage={questionsMap?.carbon?.scopeMap[scopeIndex]?.end + 1}
              pagesCount={questionsMap?.carbon?.scopeMap[scopeIndex]?.end + 1}
            />
          )}

          <Button variant="contained" onClick={onNextClick}>
            {questionsMap?.carbon?.scopeMap[scopeIndex]?.end == questionsMap?.carbon?.end
              ? 'FINISH'
              : 'NEXT'}
          </Button>
        </div>
      </Grid>
    </>
  );
};

CustomCo2.propTypes = {
  // scope: PropTypes.string,
  // setQuestionIndex: PropTypes.func,
};

CustomCo2.defaultProps = {
  // setQuestionIndex: () => {},
};

export default CustomCo2;
